import { useState, useEffect } from "react";
import { useAuth } from "../../context/auth";
import { Outlet } from "react-router-dom";
import customAxios from "../../config/index";
import Spinner from "../Spinner";

export default function PrivateRoute() {
  const [ok, setOk] = useState(false);
  const [auth, setAuth] = useAuth();

  useEffect(() => {
    const authCheck = () => {
      //async
      // const res = await customAxios.get("/api/v1/auth/user-auth");
      // if (res.data.ok) {
      //   setOk(true);
      // } else {
      //   setOk(false);
      // }
      if (auth.user?.role === 0) {
        setOk(true);
      } else {
        setOk(false);
      }
    };
    authCheck();
  }, [auth]);

  return ok ? <Outlet /> : <Spinner />;
}

import React from "react";
import {Link} from "react-router-dom";

/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';

function NavItem({item}){
    const { name, link, active } = item;
    const style = css`
        font-family: 'Roboto', sans-serif;
        padding: 0 1rem;
        a{
            text-decoration: none;
            color: ${active?'white':'#eee' } !important;
            :hover{
                text-decoration: underline;
            }
        }
    `;
    return (
        <div css={style} className="nav-item">
            <Link to={link}>{name}</Link>
        </div>
    );
}

export default NavItem;
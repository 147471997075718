/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import "../../haf/index.css";
import customAxios from "../../config/index";
import { useState } from "react";

import Hero from "../../haf/components/Home/Hero";
import Zones from "../../haf/components/Home/Zones";
import FloatingNav from "../../haf/components/General/FloatingNav";
import DekiAshenda from "./components/DekiAshenda";
import Highlighted from "../../haf/components/One/Highlighted";
import Category from "../../haf/components/Home/Category";
import API_URL from "../../config/api_url";

import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useEffect, useReducer, useRef } from "react";
import Header from "../../components/Layout/Header";
import Thanks from "../../components/Layout/Thanks";
import Footer from "../../components/Layout/Footer";

const style = css`
  position: relative;
  .after-hero {
    position: absolute;
    top: 515px;
    left: 0;
    right: 0;
    z-index: 2;
  }
  .zones-container,
  .zones-category {
    background-color: white;
    /* border-radius: 50% 0 0 0; */
    /* margin: 0 2rem; */
    padding: 1.5rem;
    min-height: 18.5rem;
    // box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    /* border-radius: 10px; */
  }
  .kulen {
    padding: 0 0.5rem;
    display: flex;
    justify-content: center;
    /* background-color: #efefef; */
  }
  .sentinel {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10rem;
  }
  @media (max-width: 790px) {
    .zones-container,
    .zones-category {
      background-color: white;

      padding: 0 0.5rem;
    }
    .after-hero {
      position: static;
    }
  }
`;

const initialState = {
  zone: "",
  zones: [],
  firstName: "",
  middleName: "",
  lastName: "",
  dekiAshenda: [],
  page: 0,
  isLoading: false,
  hasMore: true,
  votes: [
    { id: null, name: "", pics: [], rank: 3, hasPayed: false },
    { id: null, name: "", pics: [], rank: 2, hasPayed: false },
    { id: null, name: "", pics: [], rank: 1, hasPayed: false },
  ],
  nextVote: 1,
  highlighted: null,
  showSubmit: false,
  isSubmitHit: false,
};

const zonesSample = [
  {
    name: "mekelle",
    registered: 100,
    thumbnail: "photo_2024-08-12_03-49-11.jpg",
  },
  {
    name: "central",
    registered: 1000,
    thumbnail: "photo_2024-08-12_03-49-20.jpg",
  },
  {
    name: "debub",
    registered: 2500,
    thumbnail: "photo_2024-08-12_03-49-31.jpg",
  },
  {
    name: "debub mbrak",
    registered: 100,
    thumbnail: "photo_2024-08-12_03-49-40.jpg",
  },
  {
    name: "mbrak",
    registered: 300,
    thumbnail: "photo_2024-08-12_03-49-56.jpg",
  },
  {
    name: "semen merab",
    registered: 100,
    thumbnail: "photo_2024-08-12_03-50-29.jpg",
  },
  {
    name: "m'erab",
    registered: 100,
    thumbnail: "photo_2024-08-12_03-51-21.jpg",
  },
];

const thumbnails = [
  "/photo_2024-08-12_03-49-11.jpg",
  "/photo_2024-08-12_03-49-20.jpg",
  "/photo_2024-08-12_03-49-31.jpg",
  "/photo_2024-08-12_03-49-40.jpg",
  "/photo_2024-08-12_03-49-56.jpg",
  "/photo_2024-08-12_03-50-29.jpg",
  "/photo_2024-08-12_03-51-21.jpg",
  "/photo_2024-08-12_03-49-40.jpg",
  "/photo_2024-08-12_03-49-31.jpg",
  "/photo_2024-08-12_03-49-20.jpg",
  "/photo_2024-08-12_03-49-20.jpg",
  "/photo_2024-08-12_03-49-20.jpg",
  "/photo_2024-08-12_03-49-20.jpg",
];

function reducer(state, action) {
  const { type, payload } = action;
  let votes;
  switch (type) {
    case "setVotesFromLS":
      if (localStorage.nextVote == null) {
        localStorage.setItem("nextVote", state.nextVote);
      }

      if (localStorage.votes == null) {
        localStorage.setItem("votes", JSON.stringify(state.votes));
      }
      return {
        ...state,
        votes: JSON.parse(localStorage.votes),
        nextVote: parseInt(localStorage.nextVote),
      };
    case "setVote":
      const full = state.dekiAshenda.find((ga) => ga.id === payload);
      votes = state.votes
        .slice()
        .map((vote) =>
          vote.rank !== state.nextVote
            ? vote
            : { ...full, rank: state.nextVote }
        );
      let nextVote = -1;

      let sortedVotes = votes.slice().sort((a, b) => a.rank - b.rank);
      for (const vote of sortedVotes) {
        if (vote.id == null) {
          nextVote = vote.rank;
          break;
        }
      }
      localStorage.setItem("votes", JSON.stringify(votes));
      localStorage.setItem("nextVote", nextVote);
      return { ...state, votes, nextVote: nextVote };

    case "revokeVote":
      votes = [...state.votes];
      const voteToRevoke = votes.find((vote) => vote.id === payload);
      const rankToRevoke = voteToRevoke.rank;
      votes = votes.map((vote) =>
        vote.rank === rankToRevoke ? { ...vote, id: null } : vote
      );
      localStorage.setItem("votes", JSON.stringify(votes));
      localStorage.setItem("nextVote", rankToRevoke);
      return { ...state, votes, nextVote: rankToRevoke };

    case "setNextVote":
      return { ...state, nextVote: payload };

    case "toggleSubmit":
      return { ...state, showSubmit: state.showSubmit ? false : true };

    case "setHideSubmit":
      return { ...state, showSubmit: false, isSubmitHit: false };

    case "setHighlighted":
      return { ...state, highlighted: payload };

    case "addDekiAshenda":
      return {
        ...state,
        dekiAshenda: [...state.dekiAshenda, ...payload],
        page: state.page + 1,
      };

    case "changeZone":
      return {
        ...state,
        dekiAshenda: [],
        page: 0,
        hasMore: true,
        zone: payload,
      };

    case "noMore":
      return { ...state, hasMore: false };

    case "more":
      return { ...state, hasMore: true };

    case "nextPage":
      return { ...state, page: state.page + 1 };

    case "backFromPayment":
      return { ...state, showSubmit: true, isSubmitHit: true };

    case "setIsSubmitHit":
      return { ...state, isSubmitHit: payload };

    case "setZone":
      return { ...state, zone: payload };

    case "setZones":
      return { ...state, zones: payload };

    case "resetDAToZone":
      return { ...state, page: 1, dekiAshenda: payload };

    default:
      return { ...state };
  }
}

function Home() {
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const [state, dispatch] = useReducer(reducer, initialState);
  const highlitedSection = useRef(null);
  const sentinelRef = useRef(null);
  const mainRef = useRef(null);
  const navigate = useNavigate();
  const {
    votes,
    nextVote,
    highlighted,
    showSubmit,
    isSubmitHit,
    dekiAshenda,
    page,
    hasMore,
    zone,
    zones,
  } = state;

  useEffect(() => {
    //fetch zones
    customAxios.get("/getZones").then((res) => {
      const { data } = res;
      if (data.success) {
        const zones = data.zones.map((zone, index) => ({
          ...zone,
          thumbnail: thumbnails[index],
        }));
        dispatch({
          type: "setZones",
          payload: [{ name: "all", _id: "" }, ...zones],
        });
      } else {
        dispatch({ type: "setZones", payload: zonesSample });
      }
    });
  }, []);

  function onIntersection(entries) {
    const entry = entries[0];
    if (entry.isIntersecting && hasMore) {
      fetchData();
    }
  }

  useEffect(() => {
    const observer = new IntersectionObserver(onIntersection, {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    });
    if (sentinelRef.current) {
      observer.observe(sentinelRef.current);
    }

    return () => {
      if (sentinelRef.current) {
        observer.unobserve(sentinelRef.current);
      }
    };
  }, [page]);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const payed = queryParams.get("jp");

  useEffect(() => {
    dispatch({ type: "setVotesFromLS" });
    if (payed === "true") {
      dispatch({ type: "backFromPayment" });
    }
    if (id) {
      fetchSingle();
    }
  }, []);

  function fetchData() {
    if (isLoading) return;
    setIsLoading(true);
    customAxios
      .post("/actor/getActors", {
        selection: {
          address: zone,
          fName: "",
          mName: "",
          lName: "",
          page,
        },
      })
      .then((res) => {
        const { data } = res;
        if (data.success) {
          if (data.actors.length) {
            let dataDA = data.actors.map((actor) => ({
              name: actor.fname + " " + actor.mname + " " + actor.lname,
              id: actor._id,
              hasPayed: actor.isPaid,
              isActive: actor.isActive,
              pics: actor.images.map((img) => ({
                url: API_URL + "/images/" + img,
                caption: "",
              })),
              occupation: actor.occupation,
              description: actor.description,
              phone: actor.phone,
            }));
            dataDA = dataDA.filter((actor) => actor.pics.length);
            dispatch({ type: "addDekiAshenda", payload: dataDA });
          } else {
            dispatch({ type: "noMore" });
          }
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function fetchSingle() {
    customAxios
      .post("/actor/getActors", {
        selection: {
          id,
          page: 0,
        },
      })
      .then((res) => {
        const { data } = res;
        if (data.success) {
          if (data.actors.length) {
            let dataDA = data.actors.map((actor) => ({
              name: actor.fname + " " + actor.mname + " " + actor.lname,
              id: actor._id,
              pics: actor.images.map((img) => ({
                url: API_URL + "/images/" + img,
                caption: "",
              })),
              hasPayed: actor.isPaid,
              description: actor.description,
              phone: actor.phone,
            }));
            dataDA = dataDA.filter((actor) => actor.pics.length);
            // dataDA.sort((a, b)=>{
            //   if (a.hasPayed === b.hasPayed) {
            //     return 0; // They are equal in terms of sorting, so keep original order.
            // } else if (a.hasPayed) {
            //     return -1; // a should come before b.
            // } else {
            //     return 1; // b should come before a.
            // }
            // })

            if (!dataDA.length) return;
            dispatch({ type: "setHighlighted", payload: dataDA[0] });
            const element = highlitedSection.current;
            setTimeout(() => {
              element.scrollIntoView({ behavior: "smooth", block: "start" });
            }, 100);
          } else {
            // const sample = {
            //   name: "Elora Hadsh",
            //   id: "66bca984ec3e7ea9e6ecd4c6",
            //   pics: [
            //     {
            //       url: API_URL + "/images/87134861723742263369.jfif",
            //       caption: "",
            //     },
            //     {
            //       url: API_URL + "/images/87134861723742263369.jfif",
            //       caption: "",
            //     },
            //     {
            //       url: API_URL + "/images/87134861723742263369.jfif",
            //       caption: "",
            //     },
            //   ],
            //   hasPayed: false,
            //   description: "",
            // }
            // dispatch({ type: "setHighlighted", payload: sample });
          }
        }
      });
  }

  function setVote(id) {
    dispatch({ type: "setVote", payload: id });
  }

  function revokeVote(id) {
    dispatch({ type: "revokeVote", payload: id });
  }

  function selectNextVote(rank) {
    dispatch({ type: "setNextVote", payload: rank });
  }

  function submitHide() {
    dispatch({ type: "setHideSubmit" });
  }

  function submitToggle() {
    dispatch({ type: "toggleSubmit" });
  }

  function setIsSubmitHit(hit) {
    dispatch({ type: "setIsSubmitHit", payload: hit });
  }

  function handleRegisterClicked() {
    navigate("/register");
  }

  function handleVoteClicked() {
    const element = mainRef.current;
    setTimeout(() => {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }, 100);
  }

  function handleSetZone(id) {
    if (zone === id) return;
    dispatch({ type: "changeZone", payload: id });
    handleVoteClicked();
  }

  return (
    <div className="cbx" css={style} style={{ overflowX: "hidden" }}>
      {" "}
      <Header />
      <div>
        <Hero onVote={handleVoteClicked} onRegister={handleRegisterClicked} />
      </div>
      <div className="after-hero">
        {/* <div className="zones-container">
          <Zones zones={zones} setZone={handleSetZone} />
        </div> */}
        <div className="zones-category">
          <Category zones={zones} setZone={handleSetZone} activeZone={zone} />
        </div>
        <div ref={highlitedSection} className="">
          {/* <Highlighted
            detail={highlighted}
            votes={votes}
            setVote={setVote}
            revokeVote={revokeVote}
            submitVotes={submitToggle}
          /> */}
        </div>
        <div ref={mainRef} className="kulen">
          <DekiAshenda
            votes={votes}
            all={dekiAshenda}
            nextVote={nextVote}
            setVote={setVote}
            revokeVote={revokeVote}
          />
        </div>{" "}
        {hasMore && (
          <div ref={sentinelRef} className="sentinel">
            Loading....
          </div>
        )}
        {!hasMore && (
          <>
            <Thanks />
            <Footer />
          </>
        )}
      </div>
    </div>
  );
}

export default Home;

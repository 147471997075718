/** @jsxImportSource @emotion/react */
import { css, keyframes } from "@emotion/react";
const spin = keyframes`
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    `;
const style = css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.9); /* Semi-transparent white background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    .spinner {
        border: 4px solid rgba(0, 0, 0, 0.1); /* Light gray border */
        border-top: 4px solid darkcyan; /*#3498db; Blue border for spinning effect */
        border-radius: 50%;
        width: 50px;
        height: 50px;
        animation: ${spin} 1s linear infinite; /* Spinning animation */
    }
    .message{
        padding-top: 1rem;
        opacity: 0.7;
    }
`;

function SpinnerOverlay({ isLoading, message }) {
    return (
        isLoading && (
            <div css={style} className="spinner-overlay">
                <div className="spinner"></div>
                {
                    message && <div className="message">{message}</div>
                }
            </div>
        )
    );
};

export default SpinnerOverlay;
/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';

function Logo(){
    const style = css`
    font-size: x-large;
    font-weight: bold;
    color: white;
    padding: 0 1rem;
    img{
        height: 3rem;
        width: 3rem;
        border-radius: 50%;
    }
    `;
    return (
        <div css={style} className="logo">
            <img src="logo.jpg" alt="" />
        </div>
    )
}

export default Logo;
import React, { useState, useEffect } from "react";
import Layout from "./../../components/Layout/Layout";
import customAxios from "../../config/index";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Select } from "antd";
import toast from "react-hot-toast";
import "../../styles/AuthStyles.css";
import { useAuth } from "../../context/auth";
import Thanks from "../../components/Layout/Thanks";
const { Option } = Select;

const Register = () => {
  const [categories, setCategories] = useState([]);
  const [fname, setFname] = useState("");
  const [mname, setMname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [occupation, setOccupation] = useState("");
  const [description, setDescription] = useState("");
  const [referralCode, setReferralCode] = useState("");

  const [auth, setAuth] = useAuth();

  const navigate = useNavigate();
  const location = useLocation();

  const { t } = useTranslation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const refCode = params.get("ref");
    if (refCode) {
      localStorage.setItem("referralCode", refCode);
      setReferralCode(refCode);
    }
  }, [location.search]);

  //get all cat
  const getAllCategory = async () => {
    try {
      const { data } = await customAxios.get("/getZones");
      if (data?.success) {
        setCategories(data?.zones);
        console.log(categories);
      } else {
        toast.error("failed to fetch address zones.");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllCategory();
  }, []);

  // form function
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const storedReferralCode = localStorage.getItem("referralCode") || "";
      // const res = await customAxios.post("/api/v1/auth/register", {
      const res = await customAxios.post("/actor/register", {
        actor: {
          fname,
          mname,
          email,
          password,
          phone,
          address,
          referralCode: storedReferralCode,
          occupation,
          description,
        },
      });
      if (res && res.data.success) {
        toast.success(res.data && res.data.message);
        console.log(res.data);
        // navigate("/login");
        try {
          const res = await customAxios.post("/actor/login", {
            actor: { phone, password },
          });
          if (res && res.data.success) {
            toast.success(res.data && res.data.message);

            setAuth({
              ...auth,
              user: res.data.user,
              // token: res.data.token,
              token: "logsss",
            });
            localStorage.setItem("auth", JSON.stringify(res.data));
            navigate(location.state || "/dashboard/user");
          } else {
            toast.error(res.data.message);
          }
        } catch (error) {
          console.log(error);
          toast.error("Something went wrong");
        }

        // *********************************************
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };

  return (
    <Layout title="Register - ashenda">
      <div className="form-container" style={{ minHeight: "90vh" }}>
        <form onSubmit={handleSubmit}>
          <h4 className="title">{t("registration.registerTitle")}</h4>
          <div className="mb-3">
            <input
              type="text"
              value={fname}
              onChange={(e) => setFname(e.target.value)}
              className="form-control"
              id="exampleInputEmail1"
              placeholder={t("registration.fname")}
              required
              autoFocus
            />
          </div>
          <div className="mb-3">
            <input
              type="text"
              value={mname}
              onChange={(e) => setMname(e.target.value)}
              className="form-control"
              id="exampleInputEmail1"
              placeholder={t("registration.mname")}
              required
            />
          </div>
          {/* <div className="mb-3">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="form-control"
              id="exampleInputEmail1"
              placeholder={t("registration.email")}
            />
          </div> */}
          <div className="mb-3">
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="form-control"
              id="exampleInputPassword1"
              placeholder={t("registration.password")}
              required
            />
          </div>
          <div className="mb-3">
            <input
              type="text"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              className="form-control"
              id="exampleInputEmail1"
              placeholder={t("registration.phone")}
              required
            />
          </div>
          <div className="mb-3">
            <input
              type="text"
              value={occupation}
              onChange={(e) => setOccupation(e.target.value)}
              className="form-control"
              id="exampleInputEmail1"
              placeholder={t("registration.occupation")}
            />
          </div>
          {/* <div className="mb-3">
            <textarea
              rows={3}
              placeholder={t("registration.description")}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>{" "} */}
          <div className="mb-3">
            <Select
              bordered={false}
              placeholder={t("registration.zone")}
              size="large"
              showSearch
              className="form-select mb-3"
              onChange={(value) => {
                setAddress(value);
              }}
            >
              {categories && categories.length > 0 ? (
                categories.map((c) => (
                  <Option key={c._id} value={c._id}>
                    {t(`registration.zones.${c.name}`)}
                  </Option>
                ))
              ) : (
                <Option value="" disabled>
                  No zones available
                </Option>
              )}
            </Select>
          </div>
          <button type="submit" className="btn btn-primary">
            REGISTER
          </button>
        </form>
      </div>
      <Thanks />
    </Layout>
  );
};

export default Register;

/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import Zone from "./Zone";

const style = css`
display: flex;
flex-wrap: wrap;
justify-content: center;
.zone{
    margin: 1rem;
    :hover{
        margin-top: .5rem;
        box-shadow: 0 0 4px 4px #0000001d;
    }
}
`;

function Zones({zones, setZone}){
    return (
        <div css={style}>
            {
                zones.slice(1).map((zone, index)=><Zone setZone={setZone} zone={zone} key={index} />)
            }
        </div>
    );
}

export default Zones;
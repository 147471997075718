/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useTranslation } from "react-i18next";
import { Carousel } from "react-bootstrap";

import Navigation from "../General/Navigation";

const heroImages = [
  "/exp1.jpg",
  "/exp6.jpg",
  "/exp12.jpg",
  "/exp11.jpg",
  "/exp13.jpg",
  "/exp2.jpg",
  "/exp6.jpg",
  "/exp3.jpg",
  // "/exp7.jpg",
  // "/exp5.jpg",
  "/exp13.jpg",
  // "/ashenda-1.jpg",
  // "/exemplary-4.jpg",
  // "/ashenda-2.jpg",
  // "/exemplary9.jpg",
  // "/ashenda-3.jpg",
  // // "/examplary-2.jpg",
  // "/ashenda-4.jpg",
  // // "/examplary-3.jpg",
  // "/ashenda-5.jpg",
  // "/ashenda-6.jpg",
];

const style = css`
  height: 40rem;
  background: linear-gradient(135deg, #3c3075, #5f0926);
  /* border-radius: 0 0 30% 0; */
  .hero-body {
    display: flex;
    /* flex-wrap: wrap; */
    padding: 0 5rem;
    align-items: center;
    justify-content: space-between;
  }
  .hero-content {
    height: 22rem;
    max-width: 26rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .hero-text {
    font-family: "Roboto", sans-serif;
    font-size: xx-large;
    font-weight: 500;
    line-height: 1.1;
    padding-bottom: 1rem;
    color: white;
    span {
      font-size: 50px;
      color: #00970d !important;
    }
  }
  .hero-more {
    font-family: "Roboto", sans-serif;
    opacity: 0.7;
    color: white;
    padding-bottom: 1rem;
    p {
      margin: 0;
    }
  }
  .hero-action {
    button {
      border: 0;
      border-radius: 1.5rem;
      padding: 0.5rem 1.5rem;
      font-size: medium;
      font-weight: bold;
    }
    .register {
      margin-right: 1rem;
    }
    .vote {
      opacity: 0.7;
    }
  }
  .hero-image {
    /* max-width: 23rem; */
    /* max-height: 20rem; */

    img {
      max-height: inherit !important;
      max-width: 100%;
    }
  }
  @media (max-width: 790px) {
    height: unset;
    .hero-body {
      flex-wrap: wrap;
      justify-content: center;
    }
    .hero-image {
      /* display: none; */
    }
  }
  @media (max-width: 500px) {
    .hero-body {
      padding: 0 2rem;
    }
  }
`;

function Hero({ onVote, onRegister }) {
  const { t } = useTranslation();
  const navList = [
    {
      name: "exemplary",
      link: "/exemplary",
    },
    {
      name: "Register",
      link: "/register",
    },
  ];
  return (
    <div css={style} className="hero">
      <div>
        <Navigation navList={navList} />
      </div>
      <div className="hero-body">
        <div className="hero-content">
          <div className="hero-text">
            <span> {t("home.ashenda")} </span>
            {t("home.heroTitle")}
          </div>
          <div className="hero-more">
            <p>{t("home.heroBody")}</p>
            <p>{t("home.heroBody2")}</p>
          </div>
          <div className="hero-action">
            <div>
              <button onClick={onRegister} className="register">
                {t("navbar.register")}
              </button>
              <button onClick={onVote} className="vote">
                {t("navbar.vote")}
              </button>
            </div>
          </div>
        </div>
        <div className="hero-image">
          <HeroImages images={heroImages} />
        </div>
      </div>
    </div>
  );
}

const heroImagesStyle = css`
  border-radius: 0.5rem;
  width: 26rem !important;
  .hero-image-con {
    background: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 26rem;
      height: 23rem;
      object-fit: cover;
    }
  }
  @media (max-width: 1000px) {
    width: 20rem !important;
    height: 20rem;
  }
  @media (max-width: 790px) {
    /* border-radius: 1rem; */
    overflow: hidden;
    width: 100vw !important;
    height: 30rem;
    .hero-image-con img {
      width: 100vw;
      height: 30rem;
    }
  }
  .hero-image-con {
    overflow: hidden;
    position: relative;
  }

  img {
    object-fit: cover;
    object-position: top;
  }
`;
function HeroImages({ images }) {
  return (
    <div css={heroImagesStyle} className="hero-images-con">
      <Carousel pause={false} interval={4000} fade={true} controls={false}>
        {images.map((pic, index) => (
          <Carousel.Item key={index}>
            <div className="hero-image-con">
              <img src={pic} />
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
}

export default Hero;

import React, { useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { Modal, Button, Form } from "react-bootstrap";
import customAxios from "../../../config";

const RequestaLady = ({ isOpen, onClose, name, id }) => {
  const [brandName, setBrandName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [requestType, setRequestType] = useState("");

  const { t } = useTranslation();
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await customAxios.post("/vote/registerRequest", {
        request: {
          requestTo: id,
          brandName,
          email,
          phone,
          message,
          requestType,
        },
      });
      if (res && res.data.success) {
        alert(res.data.message);
      } else {
        alert(res.data.message);
      }
      onClose();
    } catch (error) {
      alert("Error submitting request");
    }
  };

  return (
    <Modal show={isOpen} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Leave a Request To: {name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formName" className="mb-3">
            <Form.Control
              type="text"
              placeholder={t("request.name")}
              value={brandName}
              onChange={(e) => setBrandName(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group controlId="formEmail" className="mb-3">
            <Form.Control
              type="email"
              placeholder={t("request.email")}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group controlId="formPhone" className="mb-3">
            <Form.Control
              type="tel"
              placeholder={t("request.phone")}
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="formDropdown" className="mb-3">
            {/* <Form.Label>What are you requesting for?</Form.Label> */}
            <Form.Control
              as="select"
              value={requestType}
              onChange={(e) => setRequestType(e.target.value)}
            >
              <option value="">{t("request.select")}</option>
              <option value="promotion">{t("request.promotion")}</option>
              <option value="hiring">{t("request.hiring")}</option>
              <option value="ambassador">{t("request.ambassador")}</option>
            </Form.Control>
          </Form.Group>{" "}
          <Form.Group controlId="formMessage" className="mb-3">
            <Form.Control
              as="textarea"
              rows={3}
              placeholder={t("request.message")}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </Form.Group>
          <Button variant="success" type="submit">
            Submit
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default RequestaLady;

/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import FloatingVote from "./FloatingVote";
import FloatingSubmit from "./FloatingSubmit";

const style = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* background-image: radial-gradient(at 0% 50%, #00000010, transparent); */
    background-color: transparent;
    position: fixed;
    right: 0;
    bottom: 0;
    width: 7.5rem;
    z-index: 200;
    .backdrop{
        position: fixed;
        right: 0;
        bottom: 0;
        width: 7rem;
        height: 100%;
        background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
        z-index: 150;
    }
    .action{
        z-index: 200;
    }
`;

function FloatingNav({ votes, nextVote, selectNextVote, submitToggle, revokeVote }){
    return (
        <div css={style}>
            <div className="action">
                {
                    votes.map((vote, index)=>
                        <FloatingVote key={index} 
                            nextVote={nextVote} 
                            selectNextVote={selectNextVote} 
                            vote={vote} 
                            revokeVote={revokeVote}/>)
                }
                <FloatingSubmit submitToggle={submitToggle} />
            </div>
        </div>
    )
}

export default FloatingNav;
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

const style = css`
    position: relative;
    height: 15rem;
    border-radius: 1rem;
    box-shadow: 0 0 2px 2px #0000000f;
    overflow: hidden;
    width: 15rem;
    transition: margin-top .2s ease-in-out;
    .desc-overlay{
        padding: 1rem;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background-image: linear-gradient(to top, #000000, #00000001);
        color: white;
    }
    .name{
        text-transform: capitalize;
        /* font-size: x-large; */
        font-weight: bold;
    }
    .discription{
        opacity: 0.7;
    }
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        vertical-align: middle;
    }
    @media ( max-width: 911px ) {
        width: 18rem;
        height: 15rem;
    }
    `;
function Zone({zone, setZone}){
    const { name, thumbnail, registered, _id } = zone;

    function handleSelected(){
        setZone(_id)
    }

    return (
        <div onClick={handleSelected} css={style} className="zone">
            <img src={thumbnail} alt="ashenda in {name} zone" />
            <div className="desc-overlay">
                <div className="name">
                    {name}
                </div>
                <div className="discription">
                    {registered}
                </div>
            </div>
        </div>
    );
}

export default Zone;
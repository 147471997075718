import React from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import Thanks from "../../../components/Layout/Thanks";

import GualAshenda from "./GualAshenda";
import Highlighted from "../One/Highlighted";

function DekiAshenda({ votes, all, nextVote, setVote, revokeVote }) {
  const style = css`
    display: flex;
    flex-wrap: wrap;
    align-items: end;
    justify-content: center;
    /* width: 90%; */
    /* max-width: 1200px; */
  `;

  const renderItemsWithThanks = () => {
    return all.map((gualAshenda, index) => {
      const isThanksNeeded = (index + 1) % 10 === 0;

      return (
        <React.Fragment key={index}>
          <div css={style}>
            <GualAshenda
              votes={votes}
              gualAshenda={gualAshenda}
              nextVote={nextVote}
              setVote={setVote}
              revokeVote={revokeVote}
            />
          </div>
          {isThanksNeeded && (
            <div>
              <Thanks />
            </div>
          )}
        </React.Fragment>
      );
    });
  };

  return <div>{renderItemsWithThanks()}</div>;

  // return (
  //   <div css={style}>
  //     {all.map((gualAshenda, index) => (
  //       <React.Fragment key={index}>
  //         <GualAshenda
  //           votes={votes}
  //           gualAshenda={gualAshenda}
  //           nextVote={nextVote}
  //           setVote={setVote}
  //           revokeVote={revokeVote}
  //           key={index}
  //         />
  //         {(index + 1) % 10 === 0 && <Thanks />}
  //       </React.Fragment>
  //     ))}
  //   </div>
  // );
}

export default DekiAshenda;

/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

const voteColors = ["#efefef", "goldenrod", "silver", "#CD7F32"]

const style = css`
border-radius: 1rem;
border: 1px solid darkcyan;
margin: 1rem;
margin-bottom: 2rem;
    box-shadow: 0 0 2px 2px #00000005 inset;
    .photos{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: end;
        padding: 2rem;
        padding-bottom: 0;
        .side{
            width: 25%;
            height: 15rem;
            padding: .5rem 1rem;
        }
        .side.one{
            width: 80%;
            height: 23rem;
        }
        .side.two{
            width: 45%;
            height: 20rem;
        }
        .full .side{
            width: 30rem;
        }
        .middle{
            width: 35%;
            height: 20rem;
            padding: .5rem 1rem;
        }
        .full.middle{
            width: 40rem;
        }
    }
    .middle img{
        box-shadow: 0 0 5px 10px #00000005;
    }
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .text-action{
        padding: 2rem;
        .name{
            border-top: 1px solid #efefef;
            padding: 0.5rem;
            font-size: large;
            font-weight: bold;
            text-align: center;
        }
        .description{
            padding: 0.5rem;
            opacity: 0.7;
            text-align: center;
        }
        .vote{
            padding: 0.5rem;
            text-align: center;
        }
        .submit{
            padding: 0.5rem;
            display: flex;
            justify-content: center;
            button{
                min-width: 15rem;
                background-color: darkcyan;
                padding: 0.5rem;
                text-align: center;
                box-shadow: none;
                color: white;
                :hover{
                    background-color: #005c5c;
                }
            }
        }
    }
    @media (max-width: 740px){
        .side, .middle{
            width: 90% !important;
            height: 20rem !important;
        }
    }
`

function Highlighted({detail, votes, setVote, revokeVote, submitVotes}){
    if(!detail)
        return null;

    const { id, name, pics, hasPayed, description } = detail;
    const classArray = ["one", "two", "three"];

    let rank = votes.find((vote)=>vote.id === id)?.rank;
    rank ??= 0;

    function handleSetVote(event){
        if (votes.find((vote)=>vote.id === id)){
            revokeVote(id);
        }
        else{
            setVote(id);
        }
    }

    function handleSubmit(){
        submitVotes();
    }

    return (
        <div css={style}>
            <div className="photos">
                {
                    pics.length > 0 ?
                    <div className={"side " + classArray[pics.length-1]}>
                        <img src={pics[0].url} alt="" />
                    </div> : null
                }
                {
                    pics.length > 2 ?
                    <div className="middle">
                        <img src={pics[2].url} alt="" />
                    </div>: null
                }
                {
                    pics.length > 1 ?
                    <div className={"side " + classArray[pics.length-1]}>
                        <img src={pics[1].url} alt="" />
                    </div> : null
                }
            </div>
            <div className="text-action">
                <div className="name">
                    {name}
                </div>
                <div className="vote">
                <button {...(!hasPayed && { disabled: true })} className="btn btn-light" onClick={handleSetVote}>
                    <svg height="48" width="48" version="1.1" id="Capa_1" 
                        xmlns="http://www.w3.org/2000/svg" 
                        xmlnsXlink="http://www.w3.org/1999/xlink" 
                        viewBox="0 0 47.94 47.94" xmlSpace="preserve"
                        stroke={hasPayed? "darkcyan": "#bbb"} >
                    <path fill={voteColors[rank]} d="M26.285,2.486l5.407,10.956c0.376,0.762,1.103,1.29,1.944,1.412l12.091,1.757
                        c2.118,0.308,2.963,2.91,1.431,4.403l-8.749,8.528c-0.608,0.593-0.886,1.448-0.742,2.285l2.065,12.042
                        c0.362,2.109-1.852,3.717-3.746,2.722l-10.814-5.685c-0.752-0.395-1.651-0.395-2.403,0l-10.814,5.685
                        c-1.894,0.996-4.108-0.613-3.746-2.722l2.065-12.042c0.144-0.837-0.134-1.692-0.742-2.285l-8.749-8.528
                        c-1.532-1.494-0.687-4.096,1.431-4.403l12.091-1.757c0.841-0.122,1.568-0.65,1.944-1.412l5.407-10.956
                        C22.602,0.567,25.338,0.567,26.285,2.486z"/>
                    </svg>
                </button>
                <div className="vote-text">
                    {hasPayed?"vote":"in-active"}
                </div>
                </div>
                <div {...(!hasPayed && { disabled: true })} onClick={handleSubmit} className="submit">
                    <button className="btn">submit</button>
                </div>
            </div>
        </div>
    )
}

export default Highlighted;
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

const colors = ["goldenrod", "silver", "#CD7F32"];
function FloatingVote({ vote, nextVote, selectNextVote, revokeVote }){
    const { id, pics, rank } = vote;
    const style = css`
        display: flex;
        justify-content: center;
        align-items: center;
        border: ${(nextVote === rank) ? "2px solid darkcyan": "2px solid transparent"};
        border-radius: 50%;
        margin: 1rem;
        width: 5rem;
        height: 5rem;
        background-color: white;
        box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.2);
        position: relative;
        img{
            width: 5rem;
            height: 5rem;
            object-fit: cover;
            border-radius: 50%;
        }
        .rank{
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            bottom: -.5rem;
            left: -.5rem;
            color:  white;
            background-color: ${colors[rank-1]};
            padding: .2rem;
            height: 1.5rem;
            width: 1.5rem;
            border-radius: 50%;
            font-size: 15px;
            font-weight: bold;
        }
        .revoke{
            position: absolute;
            right: -1rem;
            top: -1rem;
            height: 2rem;
            width: 2rem;
            padding: 0;
            border: 0;
            border-radius: 50%;
            background-color: #00000050;
            color: white;
            font-size: x-large;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    `;

    function handleSelectNextVote(){
        selectNextVote(rank)
    }

    return (
        <div onClick={handleSelectNextVote} css={style}>
            <div className="rank">{rank}</div>
            {id !== null?
                <img src={pics[0]?pics[0].url:''} alt="" />
                :
                <svg xmlns="http://www.w3.org/2000/svg" 
                    fill={nextVote === rank ? "darkcyan" : "#00000030"}
                    width="32" 
                    height="32" 
                    id="plus">
                    <path d="M9 18h7v7a1 1 0 0 0 2 0v-7h7a1 1 0 0 0 0-2h-7V9a1 1 0 0 0-2 0v7H9a1 1 0 0 0 0 2z"></path>
                </svg>
            }
            {
            id && 
            <button onClick={()=>revokeVote(id)} className="revoke">
                -
            </button>
            }
        </div>
    )
}

export default FloatingVote;
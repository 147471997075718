import React from "react";
import { useTranslation } from "react-i18next";
import {
  FaFacebook,
  FaWhatsapp,
  FaLinkedin,
  FaYoutube,
  FaTelegram,
} from "react-icons/fa";
import { Link } from "react-router-dom";
const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className="footer">
      <h1 className="text-center">
        <a href="#" target="_blank">
          {t("footer.title")} &copy; 2024
        </a>
      </h1>
      {/* <p className="text-center">{t("footer.note")}</p> */}
      <p className="text-center mt-3">
        <a href="https://www.tugza.com">{t("footer.about")}</a>|
        <Link to="https://www.tugza.com">{t("footer.contact")}</Link>|
        <Link to="#">
          {t("footer.privacy")} {t("footer.policy")}
        </Link>
      </p>{" "}
      <div>
        {" "}
        <p>
          <a href="tel:+251777390004">Phone: 0777390004</a>
        </p>
        <p>
          <a href="mailto:tugzaiit@gmail.com">Email: tugzaiit@gmail.com</a>
        </p>
      </div>
      <div className="d-flex align-items-center gap-3 mt-2 mb-4">
        <a href="#" target="_blank" rel="noopener noreferrer">
          <FaTelegram className="text-primary fs-3 hover-icon" />
        </a>
        <a href="#" target="_blank" rel="noopener noreferrer">
          <FaFacebook className="text-primary fs-3 hover-icon" />
        </a>
        <a href="#" target="_blank" rel="noopener noreferrer">
          <FaYoutube className="text-primary fs-3 hover-icon" />
        </a>
        <a href="#" target="_blank" rel="noopener noreferrer">
          <FaLinkedin className="text-primary fs-3 hover-icon" />
        </a>
        <a href="#" target="_blank" rel="noopener noreferrer">
          <FaWhatsapp className="text-primary fs-3 hover-icon" />
        </a>
      </div>
    </div>
  );
};

export default Footer;

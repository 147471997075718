import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./context/auth";
import { SearchProvider } from "./context/search";
import { CartProvider } from "./context/cart";
import "antd/dist/reset.css";

import i18next from "i18next";
import global_eng from "../src/translations/eng/trans.json";
import global_tig from "../src/translations/tig/trans.json";
import global_arabic from "../src/translations/arabic.json";
import global_hindi from "../src/translations/hindi.json";
import global_korean from "../src/translations/korean.json";
import { I18nextProvider } from "react-i18next";
i18next.init({
  lng: "tig",
  debug: true,
  interpolation: {
    escapeValue: false,
  },
  resources: {
    en: {
      translation: global_eng,
    },
    tig: {
      translation: global_tig,
    },
    arabic: {
      translation: global_arabic,
    },
    hindi: {
      translation: global_hindi,
    },
    korean: {
      translation: global_korean,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AuthProvider>
    <SearchProvider>
      <CartProvider>
        <BrowserRouter>
          <I18nextProvider i18n={i18next}>
            {" "}
            <App />
          </I18nextProvider>
        </BrowserRouter>
      </CartProvider>
    </SearchProvider>
  </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import Layout from "../../components/Layout/Layout";

function Ashenda() {
  return (
    <Layout title="aboutUs">
      <div>this is the ashenda page</div>
    </Layout>
  );
}

export default Ashenda;

import React, { useState, useEffect } from "react";
import AdminMenu from "../../components/Layout/AdminMenu";
import Layout from "../../components/Layout/Layout";
import customAxios from "../../config/index";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";

const Requests = () => {
  const [requests, setRequests] = useState([]);

  // Fetch all requests
  const getAllRequests = async () => {
    try {
      const { data } = await customAxios.get("/admin/getAllRequests");

      setRequests(data.requests);
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };

  // Lifecycle method
  useEffect(() => {
    getAllRequests();
  }, []);

  return (
    <Layout>
      <div className="container-fluid mt-4">
        <div className="row">
          <div className="col-md-3">
            <AdminMenu />
          </div>
          <div className="col-md-9">
            <h1 className="text-center mb-4">All Requests List</h1>
            <div className="row">
              {requests?.map((request) => (
                <div key={request._id} className="col-md-4 mb-4">
                  <div className="card shadow-sm">
                    <div className="card-body">
                      <h5 className="card-title text-primary">
                        {request.brandName + ": " + request.phone}
                      </h5>
                      <p className="card-text">
                        <strong>Email:</strong> {request.email}
                      </p>

                      <p className="card-text">
                        <strong>Message:</strong> {request.message}
                      </p>
                      <p className="card-text">
                        <strong>Request Type:</strong> {request.requestType}
                      </p>
                      <h5 className="card-title text-primary">
                        {request.requestTo.fname +
                          " " +
                          request.requestTo.mname +
                          ": " +
                          request.requestTo.phone}
                      </h5>

                      <p className="card-text">
                        <strong>Occupation:</strong>{" "}
                        {request.requestTo.occupation}
                      </p>
                      <p className="card-text">
                        <strong>Description:</strong>{" "}
                        {request.requestTo.description}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Requests;

/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

const thanksStyle = css`
  .thanks-container {
    padding: 2rem;
    background-color: #f8f9fa;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    position: relative;
    overflow: hidden;
    height: 200px;
  }

  .rotating-content {
    display: flex;
    align-items: center;
    animation: rotate 11s linear infinite;
    @media (min-width: 768px) {
      animation: rotate 9s linear infinite;
    }
  }

  @keyframes rotate {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(-100%);
    }
  }

  .thank-item {
    display: flex;
    align-items: center;
    margin-right: 1.5rem;
  }

  .thank-item img {
    max-height: 50px;
    margin-right: 0.2rem;
  }

  .thank-item span {
    font-size: 0.8rem;
    font-weight: bold;
    color: #343a40;
    @media (min-width: 768px) {
      font-size: 1rem;
    }
    @media (min-width: 992px) {
      font-size: 1.2rem;
    }
    @media (min-width: 1200px) {
      font-size: 1.4rem;
    }
  }
`;

function Thanks() {
  return (
    <Container css={thanksStyle}>
      <Row className="justify-content-center">
        <Col md={12}>
          <div className="thanks-container">
            <div className="rotating-content">
              <div className="thank-item">
                <img src="/gsts.png" alt="img" />
                <span>Global Society of Tigrayan Scholars- GSTS</span>
              </div>
              <div className="thank-item">
                <img src="/tourism.jpg" alt="img" />
                <span>Tigray Culture and Tourism Bureau</span>
              </div>
              <div className="thank-item">
                <img src="/anbesa.jpg" alt="img" />
                <span>Anbesa International Bank</span>
              </div>
              <div className="thank-item">
                <img src="/innovation.jpg" alt="img" />
                <span>
                  Cluster of Science, technology, innovation, research and
                  training
                </span>
              </div>
              <div className="thank-item">
                <img src="/chapa.jpg" alt="img" />
                <span>Chapa</span>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Thanks;

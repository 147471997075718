import React, { useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import customAxios from "../../config";
import { FaCheckCircle, FaExclamationCircle } from "react-icons/fa";
import API_URL from "../../config/api_url";
import toast from "react-hot-toast";
import { Modal, Form, Button } from "react-bootstrap";

const UserMenu = () => {
  const [isPaid, setIsPaid] = useState(false);
  const [description, setDescription] = useState("");
  const [occupation, setOccupation] = useState("");
  const [email, setEmail] = useState("");
  const [userLink, setUserLink] = useState("");
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await customAxios.put("/actor/updateDescription", {
        actor: { description, occupation, email },
      });
      alert("updated successfully!");
      handleCloseModal();
    } catch (error) {
      alert("Error updating story");
    }
  };

  useEffect(() => {
    const fetchActorData = async () => {
      try {
        const res = await customAxios.get("/actor/getActorById");
        const { data } = res;
        setIsPaid(data.user[0].isPaid);
        setDescription(data.user[0].description || "");
        setOccupation(data.user[0].occupation || "");
        setEmail(data.user[0].email || "");
        setUserLink(`${API_URL}/${data.user[0]._id}`);
      } catch (error) {
        console.error("Failed to fetch actor data", error);
      }
    };

    fetchActorData();
  }, []);

  return (
    <div style={{ marginTop: "60px" }}>
      <div className="text-center dashboard-menu">
        <div className="list-group">
          <Link to="/dashboard/user">
            <h4>Dashboard</h4>
          </Link>

          <NavLink
            to="/dashboard/user/password"
            className="list-group-item list-group-item-action"
          >
            {t("userDashboard.changePassword")}
          </NavLink>
          <NavLink
            to="/dashboard/user/upload"
            className="list-group-item list-group-item-action"
          >
            {t("userDashboard.uploadPhoto")}
          </NavLink>
          <NavLink
            to="/dashboard/user/pay"
            className="list-group-item list-group-item-action"
          >
            {t("userDashboard.payNow")}
          </NavLink>

          <div
            className="list-group-item list-group-item-action"
            onClick={handleShowModal}
          >
            <Button variant="link" className="text-decoration-none">
              {t("userDashboard.updateDescription")}
            </Button>
          </div>

          <div>
            {isPaid ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "green",
                }}
              >
                <FaCheckCircle
                  style={{ marginRight: "8px", fontSize: "20px" }}
                />
                <span>Paid</span>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "orange",
                }}
              >
                <FaExclamationCircle
                  style={{ marginRight: "8px", fontSize: "20px" }}
                />
                <span>Not Paid, pay to be voted</span>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Modal */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Update Description</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formInput2" className="mb-3">
              <Form.Control
                type="text"
                placeholder={t("update.email")}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formInput3" className="mb-3">
              <Form.Control
                type="text"
                placeholder={t("update.occupation")}
                value={occupation}
                onChange={(e) => setOccupation(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formInput1" className="mb-3">
              <Form.Control
                as="textarea"
                rows={3}
                //type="text"
                placeholder={t("update.description")}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                required
              />
            </Form.Group>

            <Button variant="success" type="submit">
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default UserMenu;

/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import NavItem from "./NavItem";
import Logo from "./Logo";

function Navigation({ navList }) {
  const style = css`
    display: flex;
    padding: 1rem;
    padding-bottom: 4rem;
    align-items: center;
  `;
  return (
    <div css={style} className="nav">
      <div className="logo-con">
        <Logo />
      </div>
      {navList.map((navItem, index) => (
        <NavItem key={index} item={navItem} />
      ))}
    </div>
  );
}

export default Navigation;

import Layout from "../../components/Layout/Layout";

function AboutUs() {
  return (
    <Layout title="aboutUs">
      <div>this is the AboutUs page</div>
    </Layout>
  );
}

export default AboutUs;

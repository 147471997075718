import React, { useState, useEffect } from "react";
import UserMenu from "../../components/Layout/UserMenu";
import Layout from "./../../components/Layout/Layout";
import { useAuth } from "../../context/auth";
import toast from "react-hot-toast";
import { Select } from "antd";
import useCategory from "../../../src/hooks/useCategory";
import customAxios from "../../config/index";
const { Option } = Select;
const Profile = () => {
  //context
  const [auth, setAuth] = useAuth();
  //state
  const [fname, setFname] = useState("");
  const [mname, setMname] = useState("");
  const [lname, setLname] = useState("");
  const [image1, setImage1] = useState(null);
  const [image2, setImage2] = useState(null);
  const [image3, setImage3] = useState(null);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [description, setDescription] = useState("");
  const [profile, setProfile] = useState(null);
  const categories = useCategory();

  // //get user data
  // useEffect(() => {
  //   const { email, fname, phone, address } = auth?.user;
  //   setFname(fname);
  //   setPhone(phone);
  //   setEmail(email);
  //   setAddress(address);
  // }, [auth?.user]);

  const getMyProfile = async () => {
    try {
      const { data } = await customAxios.get("/actor/getActorById");
      if (data?.success) {
        if (data?.success) {
          console.log(data);
          setFname(data?.user[0].fname || "");
          setMname(data?.user[0].mname || "");
          setLname(data?.user[0].lname || "");
          setEmail(data?.user[0].email || "");
          setPhone(data?.user[0].phone || "");
          setAddress(data?.user[0].zone.name || "");
          setDescription(data?.user[0].description || "");
        }
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong in getting profile");
    }
  };

  useEffect(() => {
    getMyProfile();
    // getAllCategory();
  }, []);

  // form function
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const profileData = new FormData();
      profileData.append("fname", fname);
      profileData.append("mname", mname);
      profileData.append("lname", lname);
      profileData.append("description", description);
      profileData.append("email", email);
      profileData.append("phone", phone);
      profileData.append("image1", image1);
      profileData.append("image2", image2);
      profileData.append("image3", image3);
      profileData.append("address", address);
      const { data } = customAxios.put(
        "/api/v1/user/update-profile",
        profileData
      );
      if (data?.error) {
        toast.error(data?.error);
      } else if (data.success) {
        setAuth({ ...auth, user: data?.updatedUser });
        let ls = localStorage.getItem("auth");
        ls = JSON.parse(ls);
        ls.user = data.updatedUser;
        localStorage.setItem("auth", JSON.stringify(ls));
        toast.success("Profile Updated Successfully");
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };
  return (
    <Layout title={"Your Profile"}>
      <div className="container-fluid m-6 p-3 dashboard">
        <div className="row">
          <div className="col-md-3">
            <UserMenu />
          </div>
          <div className="col-md-8">
            <div className="form-container" style={{ marginTop: "-40px" }}>
              <form onSubmit={handleSubmit}>
                <h4 className="title">USER PROFILE</h4>
                <div className="mb-3">
                  <input
                    type="text"
                    value={fname}
                    onChange={(e) => setFname(e.target.value)}
                    className="form-control"
                    id="exampleInputEmail1"
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="text"
                    value={mname}
                    onChange={(e) => setMname(e.target.value)}
                    className="form-control"
                    id="exampleInputEmail1"
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="text"
                    value={lname}
                    onChange={(e) => setLname(e.target.value)}
                    className="form-control"
                    id="exampleInputEmail1"
                  />
                </div>

                <div className="mb-3">
                  <input
                    type="text"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    className="form-control"
                    id="exampleInputEmail1"
                    disabled
                  />
                </div>
                {/* Image Uploads */}
                {/* {[image1, image2, image3].map((image, index) => (
                  <div className="mb-3" key={index}>
                    <label className="btn btn-outline-secondary col-md-12">
                      {image ? image.name : `Upload Photo ${index + 1}`}
                      <input
                        type="file"
                        accept="image/*"
                        onChange={(e) => {
                          const file = e.target.files[0];
                          if (index === 0) setImage1(file);
                          if (index === 1) setImage2(file);
                          if (index === 2) setImage3(file);
                        }}
                        hidden
                      />
                    </label>
                    {image && (
                      <div className="text-center mt-2">
                        <img
                          src={URL.createObjectURL(image)}
                          alt={`image${index + 1}`}
                          height={"200px"}
                          className="img img-responsive"
                        />
                      </div>
                    )}
                  </div>
                ))} */}

                {/* <div className="mb-3">
                  <label className="btn btn-outline-secondary col-md-12">
                    {image1 ? image1.name : "Upload Photo"}
                    <input
                      type="file"
                      name="image1"
                      accept="image/*"
                      onChange={(e) => setImage1(e.target.files[0])}
                      hidden
                    />
                  </label>
                </div>
                <div className="mb-3">
                  {image1 && (
                    <div className="text-center">
                      <img
                        src={URL.createObjectURL(image1)}
                        alt="image1"
                        height={"200px"}
                        className="img img-responsive"
                      />
                    </div>
                  )}
                </div>

                <div className="mb-3">
                  <label className="btn btn-outline-secondary col-md-12">
                    {image2 ? image2.name : "Upload Photo"}
                    <input
                      type="file"
                      name="image2"
                      accept="image/*"
                      onChange={(e) => setImage2(e.target.files[0])}
                      hidden
                    />
                  </label>
                </div>
                <div className="mb-3">
                  {image2 && (
                    <div className="text-center">
                      <img
                        src={URL.createObjectURL(image2)}
                        alt="product_photo"
                        height={"200px"}
                        className="img img-responsive"
                      />
                    </div>
                  )}
                </div>
                <div className="mb-3">
                  <label className="btn btn-outline-secondary col-md-12">
                    {image3 ? image3.name : "Upload Photo"}
                    <input
                      type="file"
                      name="image3"
                      accept="image/*"
                      onChange={(e) => setImage3(e.target.files[0])}
                      hidden
                    />
                  </label>
                </div>
                <div className="mb-3">
                  {image3 && (
                    <div className="text-center">
                      <img
                        src={URL.createObjectURL(image3)}
                        alt="product_photo"
                        height={"200px"}
                        className="img img-responsive"
                      />
                    </div>
                  )}
                </div> */}

                <div className="mb-3">
                  <textarea
                    type="text"
                    value={description}
                    placeholder="write a description about yourself"
                    className="form-control"
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>

                <div className="mb-3">
                  <Select
                    bordered={false}
                    placeholder="Select a category"
                    size="large"
                    showSearch
                    className="form-select mb-3"
                    onChange={(value) => {
                      setAddress(value);
                    }}
                    value={address}
                  >
                    {categories && categories.length > 0 ? (
                      categories.map((c) => (
                        <Option key={c._id} value={c._id}>
                          {c.name}
                        </Option>
                      ))
                    ) : (
                      <Option value="" disabled>
                        No zones available
                      </Option>
                    )}
                  </Select>
                </div>

                <button type="submit" className="btn btn-primary">
                  UPDATE
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Profile;

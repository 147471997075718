import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import UserMenu from "../../components/Layout/UserMenu";
import toast from "react-hot-toast";
import customAxios from "../../config/index";
import API_URL from "../../config/api_url";
import { Modal, Button } from "antd";

const UploadPhoto = () => {
  const [image1, setImage1] = useState(null);
  const [image2, setImage2] = useState(null);
  const [image3, setImage3] = useState(null);
  const [imageLabel, setImageLabel] = useState("");
  const [newImage, setNewImage] = useState(null);
  const [visible, setVisible] = useState(false);
  const [imageDataUrl, setImageDataUrl] = useState("");

  const getMyProfile = async () => {
    try {
      // const { data } = await customAxios.get("/api/v1/auth/get-profile");
      const { data } = await customAxios.get("/actor/getActorById");
      if (data?.success) {
        if (data?.success) {
          console.log(data.user[0]);
          setImage1(() => data?.user[0].image1);
          setImage2(data?.user[0].image2);
          setImage3(data?.user[0].image3);
        }
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong in getting profile");
    }
  };

  useEffect(() => {
    getMyProfile();
  }, []);

  // Handle image update
  const handleImageUpdate = async () => {
    if (!newImage || !imageLabel) {
      toast.error("No image selected or no image type specified");
      return;
    }

    const fileName = newImage.name;
    const fileSize = newImage.size;
    const fileNameParts = fileName.split(".");
    const fileExtension = fileNameParts.length > 1 ? fileNameParts.pop() : "";

    if (fileSize > 10000000) {
      toast.error("Image should be less than 10MB");
      return;
    }

    // Read the file and get the Data URL
    let dataUrl = "";
    try {
      dataUrl = await readFileAsDataURL(newImage);
    } catch (error) {
      toast.error("Error reading image. Please try uploading again.");
      console.error(error);
      return;
    }

    if (!dataUrl) {
      toast.error("Failed to load image data. Try uploading again.");
      return;
    }

    const UploadPhoto = {
      images: {
        content: dataUrl,
        name: imageLabel,
        type: fileExtension,
        size: fileSize,
      },
    };

    const url =
      imageLabel === "new" ? "/actor/uploadImages" : `/actor/updateImages`;
    const method = imageLabel === "new" ? "post" : "put";

    try {
      const { data } = await customAxios[method](url, UploadPhoto);
      if (data?.success) {
        toast.success("Image uploaded successfully");
        setVisible(false);
        setNewImage(null);
        setImageLabel("");
        setImageDataUrl("");
        getMyProfile();
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong while updating the image");
    }
  };

  // Function to read file as Data URL
  const readFileAsDataURL = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };
  // Handle image deletion
  const handleImageDelete = async (imageType, setter) => {
    try {
      let answer = window.confirm("Are You Sure to delete this image? ");
      if (!answer) return;
      const { data } = await customAxios.delete(
        `/actor/delete-image/${imageType}`
      );
      if (data.success) {
        toast.success("Image deleted successfully");
        setter(null);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong in deleting the image");
    }
  };
  const allImagesUploaded = image1 && image2 && image3;
  return (
    <Layout title={"Dashboard - Update Photo"}>
      <div className="container-fluid m-6 p-3 dashboard">
        <div className="row">
          <div className="col-md-3">
            <UserMenu />
          </div>
          <div className="col-md-9">
            <h1>Manage Your Photos</h1>
            <div className="w-75">
              <div className="row">
                {[
                  { key: "image1", url: image1, setter: setImage1 },
                  { key: "image2", url: image2, setter: setImage2 },
                  { key: "image3", url: image3, setter: setImage3 },
                ]
                  .filter(({ url }) => url)
                  .map(({ key, url, setter }) => (
                    <div className="col-md-4 mb-3 mr-2" key={key}>
                      {/* <h4>{key}</h4> */}
                      <div className="text-center">
                        {url ? (
                          <img
                            src={`${API_URL}/images/${url}`}
                            alt={key}
                            height={"200px"}
                            className="img img-responsive"
                          />
                        ) : (
                          <p>No Image</p>
                        )}
                      </div>
                      <Button
                        className="btn btn-primary mt-2"
                        onClick={() => {
                          setImageLabel(key);
                          setVisible(true);
                        }}
                      >
                        Edit
                      </Button>
                      <Button
                        className="btn btn-danger mt-2 ms-2"
                        onClick={() => handleImageDelete(setter)}
                        disabled
                      >
                        Delete
                      </Button>
                    </div>
                  ))}
              </div>
              <Button
                className="btn btn-primary mt-3"
                onClick={() => {
                  setImageLabel("new");
                  setVisible(true);
                }}
                disabled={allImagesUploaded}
              >
                Upload New Image
              </Button>
              <Modal
                title="Update Image"
                visible={visible}
                onCancel={() => setVisible(false)}
                footer={[
                  <Button key="back" onClick={() => setVisible(false)}>
                    Cancel
                  </Button>,
                  <Button
                    key="submit"
                    type="primary"
                    onClick={handleImageUpdate}
                  >
                    Submit
                  </Button>,
                ]}
              >
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => setNewImage(e.target.files[0])}
                />
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default UploadPhoto;

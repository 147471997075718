/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

function FloatingSubmit({ submitToggle }){
    const style = css`
    border: 0;
    border-radius: 50%;
    margin: 1rem;
    width: 5rem;
    height: 5rem;
    background-color: darkcyan;
    color: white;
    font-size: medium;
    font-weight: bold;
    box-shadow: 0 0 2px rgba(100, 100, 100, 0.4);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
    return (
        <button css={style} onClick={submitToggle}>
            <span>
                <svg xmlns="http://www.w3.org/2000/svg" 
                    viewBox="0 0 24 24" id="upload"
                    height={32}
                    width={32} >
                    <path fill="cyan" d="m15.707 5.293-3-3a1 1 0 0 0-1.414 0l-3 3a1 1 0 0 0 1.414 1.414L11 5.414V17a1 1 0 0 0 2 0V5.414l1.293 1.293a1 1 0 0 0 1.414-1.414Z"></path>
                    <path fill="darkcyan" d="M18 9h-5v8a1 1 0 0 1-2 0V9H6a3.003 3.003 0 0 0-3 3v7a3.003 3.003 0 0 0 3 3h12a3.003 3.003 0 0 0 3-3v-7a3.003 3.003 0 0 0-3-3Z"></path>
                </svg>
            </span>
                submit
        </button>
    )
}

export default FloatingSubmit;
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import Layout from "../../components/Layout/Layout";
import UserMenu from "../../components/Layout/UserMenu";
import { useAuth } from "../../context/auth";
import { useTranslation } from "react-i18next";
import customAxios from "../../config";
import API_URL from "../../config/api_url";
import { FaCheckCircle, FaExclamationCircle } from "react-icons/fa";

const Dashboard = () => {
  const [auth] = useAuth();

  const [userId, setUserId] = useState("");
  const [fname, setFname] = useState("");
  const [mname, setMname] = useState("");
  const [lname, setLname] = useState("");
  const [image1, setImage1] = useState(null);
  const [image2, setImage2] = useState(null);
  const [image3, setImage3] = useState(null);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [votes, setVotes] = useState(0);
  const [isPaid, setIsPaid] = useState(false);

  const { t } = useTranslation();
  const getMyProfile = async () => {
    try {
      const { data } = await customAxios.get("/actor/getActorById");
      if (data?.success) {
        // console.log(data);
        setUserId(data?.user[0]._id);
        setFname(data?.user[0].fname || "");
        setMname(data?.user[0].mname || "");
        setLname(data?.user[0].lname || "");
        setEmail(data?.user[0].email || "");
        setPhone(data?.user[0].phone || "");
        setAddress(data?.user[0].zone.name || "");
        setVotes(data?.user[0].votes || 0);
        setIsPaid(data?.user[0].isPaid);
        setImage1(data?.user[0].image1);
        setImage2(data?.user[0].image2);
        setImage3(data?.user[0].image3);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong in getting profile");
    }
  };

  useEffect(() => {
    getMyProfile();
  }, []);

  return (
    <Layout title={"Dashboard"}>
      <div className="container-fluid m-6 p-3 dashboard">
        <div className="row">
          <div className="col-md-3">
            <UserMenu />
          </div>

          <div className="col-md-9">
            <div className="w-100">
              <div className="row">
                {image1 && (
                  <div className="col-md-4 mb-3">
                    <img
                      src={`${API_URL}/images/${image1}`}
                      alt="Profile Image 1"
                      height={"200px"}
                      className="img img-responsive"
                    />
                  </div>
                )}
                {image2 && (
                  <div className="col-md-4 mb-3">
                    <img
                      src={`${API_URL}/images/${image2}`}
                      alt="Profile Image 2"
                      height={"200px"}
                      className="img img-responsive"
                    />
                  </div>
                )}
                {image3 && (
                  <div className="col-md-4 mb-3">
                    <img
                      src={`${API_URL}/images/${image3}`}
                      alt="Profile Image 3"
                      height={"200px"}
                      className="img img-responsive"
                    />
                  </div>
                )}
              </div>
              <h4>
                {fname} {mname && `${mname} `}
                {lname}
              </h4>
              <h5>
                {t("user.email")}: {email}
              </h5>
              <h5>
                {t("user.phone")}: {phone}
              </h5>
              <h5>
                {t("user.address")}: {address}
              </h5>
              <h6>
                {t("user.votes")}: {votes}
              </h6>
              {/* <h4>Your profile link: {`${API_URL}/${userId}`}</h4> */}

              <h6>
                {t("user.paymentStatus")}:{" "}
                {isPaid ? t("user.paid") : t("user.notPaid")}
              </h6>
              <h6
                style={{ wordWrap: "break-word", overflowWrap: "break-word" }}
              >
                {t("user.profileLink")}{" "}
                <a href={`${API_URL}/${userId}`}>{`${API_URL}/${userId}`}</a>
              </h6>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Dashboard;

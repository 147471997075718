import React from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import GualAshenda from "./GualAshenda";

function DekiAshenda({ votes, all, nextVote, setVote, revokeVote }) {
  const style = css`
    display: flex;
    flex-wrap: wrap;
    align-items: end;
    justify-content: center;
    /* width: 90%; */
    /* max-width: 1200px; */
  `;

  return (
    <div css={style}>
      {all.map((gualAshenda, index) => (
        <GualAshenda
          votes={votes}
          gualAshenda={gualAshenda}
          nextVote={nextVote}
          setVote={setVote}
          revokeVote={revokeVote}
          key={index}
        />
      ))}
    </div>
  );
}

export default DekiAshenda;

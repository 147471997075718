/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useReducer, useRef, useState } from 'react'
import customAxios from '../../config';
import API_URL from '../config/api';

const style = css`
  display: flex;
  flex-wrap: wrap;
  align-items: end;
  .key, .amount{
    margin-top: 1rem;
    :focus{
      box-shadow: none;
      border-color: darkcyan;
    }
  }
  .download, .generate{
    margin-top: 1rem;
    color: white;
    background-color: darkcyan;
    :hover, :active{
      background-color: #003f3f;
    }
  }
  .generator, .downloader{
    padding: 2rem;
    margin: 1rem;
    border-radius: 1rem;
    box-shadow: 0 0 2px 2px #00000010;
    div{
      padding: 1rem;
    }
  }
`

const initialState = {
  key: '',
  genKey: '',
  amount: '',
  genAmount: '',
}

function reduce(state, action){
  const {type, payload} = action;
  switch(type){
    case 'setKey':
      return {...state, key: payload}
    
    case 'setGenKey':
      return {...state, genKey: payload}
    
    case 'setAmount':
      return {...state, amount: payload}
    
    case 'setGenAmount':
      return { ...state, genAmount: payload }

    case 'reset':
      return {initialState}
      
    default:
      return {...state};
  }
}

function Generate() {
  const [state, dispatch] = useReducer(reduce, initialState)
  const {amount, key, genAmount, genKey} = state;
  const keyRef = useRef(null);
  const genKeyRef = useRef(null);
  const amountRef = useRef(null);
  const genAmountRef = useRef(null);

  function onChangeKey(){
    const value = keyRef.current.value
    dispatch({type: 'setKey', payload: value})
  }

  function onChangeGenKey(){
    const value = genKeyRef.current.value
    dispatch({type: 'setGenKey', payload: value})
  }

  function onChangeAmount(){
    const value = amountRef.current.value;
    dispatch({type: 'setAmount', payload: value})
  }

  function onChangeGenAmount(){
    const value = genAmountRef.current.value;
    dispatch({type: 'setGenAmount', payload: value})
  }

  function generateCards(){
    const req = { key: genKey, amount: genAmount }
    customAxios.post('/card/generate', req)
    .then((res)=>{
      const {data} = res;
      if(data.success){
        alert('successfully generated')
      }
    })
    .catch((e)=>{alert(e.getMessage())})
    .finally(()=>{

    })
  }

  function fetchPdf(){
    const req = { key, amount }
    customAxios.post('/card/downloadCards', req)
    .then((res)=>{
      const {data} = res;
      if(data.success){
        const a = document.createElement('a');
        a.href = API_URL+'/pdfCards/'+data.link;
        a.download = 'tugzacard(T-card).pdf'
        a.textContent = 'click to download'
        // document.querySelector('.downloader').appendChild(a)
        a.click();
      }
    })
    .catch((e)=>console.error(e))
    .finally(()=>{

    })
  }
  return (
    <>
      <div css={style}>
        {/* <div className='generator'>
          <div>
            <span>generate cards</span>
          </div>
          <input ref={genKeyRef} value={genKey} onChange={onChangeGenKey} className='form-control key' type="text" placeholder='key' />
          <input ref={genAmountRef} value={genAmount} onChange={onChangeGenAmount} className='form-control amount' type="number" placeholder='amount' />
          <button onClick={generateCards} className='btn btn-primary form-control download'>generate</button>
        </div> */}

        <div className='downloader'>
          <div>
            <span>download cards</span>
          </div>
          <input ref={keyRef} value={key} onChange={onChangeKey} className='form-control key' type="text" placeholder='key' />
          <input ref={amountRef} value={amount} onChange={onChangeAmount} className='form-control amount' type="number" placeholder='amount' />
          <button onClick={fetchPdf} className='btn btn-primary form-control download'>download</button>
        </div>
      </div>
    </>
  )
}

export default Generate

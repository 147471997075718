/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useState, useEffect, useRef } from "react";
import UserMenu from "../../components/Layout/UserMenu";
import Layout from "../../components/Layout/Layout";
import customAxios from "../../config/index";
import { useNavigate, useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import "../../styles/AuthStyles.css";
import { useTranslation } from "react-i18next";

const paymentStyle = css`
  min-height: 150px;
  .success {
  }
  .description {
    text-align: center;
  }
  .amount-payed {
    font-size: 30px;
  }
  .recurring {
    opacity: 0.7;
  }
  .options {
    box-sizing: border-box !important;
    padding: 1rem 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 15px;
  }
  .option {
    height: 150px;
    max-width: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0 0 2px 2px #55555520;
    border-radius: 1rem;
    padding: 1rem;
  }
  .option2 {
    border: 2px solid darkcyan;
  }
  input {
    width: unset;
  }
  .t-card-pay {
    margin: 1rem 0;
    color: white;
    border: 0;
    background-color: darkcyan;
  }
  .chapa-pay {
    margin: 0.5rem 0;
    color: white;
    border: 0;
    background-color: darkcyan;
  }
`;

const Pay = () => {
  const { t } = useTranslation();
  const [isPaid, setIsPaid] = useState(null);
  const [tCard, setTcard] = useState("");
  const [phone, setPhone] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const tCardRef = useRef(null);

  useEffect(() => {
    const fetchPaymentStatus = async () => {
      //  const params = queryString.parse(location.search);
      //   if (params.st) {
      const params = new URLSearchParams(location.search);

      if (params.has("st")) {
        await customAxios.get("/actor/verifyPayment");
      }
      customAxios.get("/actor/getActorById").then((res) => {
        const { data } = res;
        setIsPaid(data.user[0].isPaid);
      });
    };

    fetchPaymentStatus();
  }, [isPaid]);

  const payWithTCard = async (e) => {
    e.preventDefault();
    try {
      const { data } = await customAxios.post("/actor/payWithCard", {
        actor: { card: tCard },
      });
      if (data.success) {
        setIsPaid(true);
        toast.success(data.message);
        navigate("/dashboard/user");
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error("Error processing card payment:", error);
      toast.error("Something went wrong");
    }
  };

  const payWithChapa = async (e) => {
    e.preventDefault();
    try {
      const { data } = await customAxios.get("/actor/payWithChapa");
      if (data.success) {
        toast.success(data.message);
        window.location.href = data.url;
        // window.open(data.url, "_blank");
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error("Error processing Chapa payment:", error);
      toast.error("Something went wrong");
    }
  };

  function handleSetTcard() {
    const value = tCardRef.current.value;
    setTcard(value);
  }

  return (
    <Layout title={"Payment"}>
      <div className="container-fluid m-6 p-3 dashboard">
        <div className="row">
          <div className="col-md-3">
            <UserMenu />
          </div>
          <div className="col-md-9">
            <div className="form-container" style={{ marginTop: "-40px" }}>
              {!isPaid ? (
                <div css={paymentStyle}>
                  <div className="description">
                    <div className="amount-payed">
                      30 birr/$2{" "}
                      <p style={{ fontSize: "14px" }}>{t("notice.pay")}</p>
                    </div>
                    <div className="recurring">billed only once.</div>
                  </div>
                  <div className="options">
                    <div className="option">
                      <input
                        ref={tCardRef}
                        value={tCard}
                        onChange={handleSetTcard}
                        className="form-control"
                        type="num"
                        placeholder="nos in the T-card"
                      />
                      <button className="btn t-card-pay" onClick={payWithTCard}>
                        pay by T-card
                      </button>
                    </div>
                    <div className="option option2">
                      <div className="option-logo">
                        <svg
                          version="1.1"
                          id="Layer_1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          x="0px"
                          y="0px"
                          viewBox="0 0 167 63"
                          xmlSpace="preserve"
                          width="150"
                          height={48}
                        >
                          <path
                            fill="#8dc63f"
                            opacity="0.59"
                            enableBackground="new"
                            d="M11.8,26.2h23.5l0,0l0,0c0,3.6-2.9,6.5-6.5,6.5c0,0,0,0,0,0h-17c-1.8,0-3.3-1.5-3.3-3.3l0,0l0,0C8.6,27.7,10,26.2,11.8,26.2L11.8,26.2L11.8,26.2z"
                          ></path>
                          <path
                            fill="#8dc63f"
                            opacity="0.59"
                            enableBackground="new"
                            d="M35.1,17.6l-4.7,6.5h6.2c3.6,0,6.5-2.9,6.5-6.5c0,0,0,0,0,0H35.1z"
                          ></path>
                          <path
                            fill="#8dc63f"
                            opacity="0.59"
                            enableBackground="new"
                            d="M22.4,24l4.6-6.4H11.9C16.3,17.6,20.4,20.1,22.4,24z"
                          ></path>
                          <path
                            fill="#7dc400"
                            d="M22.4,24.1l0-0.1l-0.1,0.1H22.4z"
                          ></path>
                          <path
                            fill="#7dc400"
                            d="M27.2,17.4L27,17.6L22.4,24l0,0.1h-0.1l-1.5,2.1l-4.9,6.7c-1.9,2.2-5.3,2.5-7.5,0.6S5.9,28.2,7.8,26c1-1.1,2.4-1.8,3.9-1.9h10.7l0.1-0.1c-2-3.9-6.1-6.4-10.5-6.4l0,0h-0.7C4.6,18-0.4,23.6,0,30.1s6,11.5,12.5,11.1c3.4-0.2,6.6-1.9,8.6-4.5l0.4-0.6l0,0l7.2-9.9l1.5-2.1l4.7-6.5l1.2-1.6C33.4,13.9,29.3,14.5,27.2,17.4z"
                          ></path>
                          <path
                            fill="#7dc400"
                            d="M81.3,21.5v2.4c0.6-0.6,1.2-1,2-1.3c0.8-0.3,1.7-0.5,2.5-0.5c4.6,0,6.9,2.7,6.9,8.1v11l0,0c-3.1,0-5.7-2.6-5.7-5.7v-4.9c0.1-0.9-0.2-1.8-0.8-2.5c-0.6-0.6-1.3-0.9-2.1-0.8c-0.8,0-1.5,0.3-2.1,0.8c-0.6,0.7-0.8,1.6-0.8,2.5v10.6l0,0c-3.2,0-5.7-2.5-5.7-5.7c0,0,0,0,0,0V15.8l0,0C78.7,15.7,81.3,18.3,81.3,21.5C81.3,21.5,81.3,21.5,81.3,21.5z"
                          ></path>
                          <path
                            fill="#7dc400"
                            d="M116.1,31.9c0-1.8,0.5-3.5,1.5-4.9c1-1.5,2.3-2.7,3.9-3.5c2.8-1.5,6.2-1.7,9.2-0.6c1.3,0.5,2.4,1.2,3.4,2.1c1,0.9,1.8,2,2.3,3.1c0.6,1.2,0.8,2.6,0.8,3.9c0,1.3-0.3,2.7-0.9,3.9c-0.5,1.2-1.3,2.3-2.3,3.1c-1,0.9-2.1,1.6-3.4,2.1c-2.6,0.9-5.4,0.9-7.9,0l-0.4-0.2l-0.4-0.2v7.6l0,0c-3.2,0-5.7-2.6-5.7-5.7L116.1,31.9z M121.8,32c0,0.8,0.2,1.6,0.7,2.3c0.4,0.7,1,1.3,1.8,1.7c0.7,0.4,1.5,0.6,2.3,0.6c0.8,0,1.7-0.2,2.4-0.6c0.7-0.4,1.3-1,1.8-1.7c0.4-0.7,0.7-1.5,0.7-2.3c0-1.3-0.5-2.5-1.4-3.3c-1.9-1.8-4.9-1.8-6.7,0C122.4,29.6,121.8,30.7,121.8,32L121.8,32z"
                          ></path>
                          <path
                            fill="#7dc400"
                            d="M148.6,22.1c-5.4,0-9.8,4.4-9.8,9.8s4.4,9.8,9.8,9.8c2.1,0,4.1-0.7,5.8-1.9c1,1.2,2.4,1.9,4,1.9v-9.8C158.4,26.5,154,22.1,148.6,22.1z M148.6,36.6c-2.6,0-4.6-2.1-4.6-4.7c0-2.6,2.1-4.6,4.7-4.6c2.6,0,4.6,2.1,4.6,4.7c0,0,0,0,0,0C153.2,34.5,151.1,36.6,148.6,36.6C148.6,36.6,148.6,36.6,148.6,36.6z"
                          ></path>
                          <path
                            fill="#7dc400"
                            d="M104.4,22.1c-5.4,0-9.8,4.4-9.8,9.8c0,5.4,4.4,9.8,9.8,9.8c2.1,0,4.1-0.7,5.8-1.9c1,1.2,2.4,1.9,4,1.9v-9.8C114.2,26.5,109.8,22.1,104.4,22.1z M104.4,36.6c-2.6,0-4.7-2.1-4.7-4.6s2.1-4.7,4.6-4.7c2.6,0,4.7,2.1,4.7,4.6c0,0,0,0,0,0C109,34.5,107,36.6,104.4,36.6z"
                          ></path>
                          <path
                            fill="#7dc400"
                            d="M64.7,35.8c-3.9,0-7-3.2-7-7c0-3.9,3.2-7,7-7c1.9,0,3.6,0.8,4.9,2.1l4.1-4c-4.9-5-13-5.1-18-0.2s-5.1,13-0.2,18s13,5.1,18,0.2c0,0,0.1-0.1,0.1-0.1l-4-4C68.4,35.1,66.6,35.8,64.7,35.8z"
                          ></path>
                        </svg>
                      </div>
                      <button className="btn chapa-pay" onClick={payWithChapa}>
                        pay with chapa
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="alert alert-success">IT IS PAID</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Pay;

/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useTranslation } from "react-i18next";

const style = css`
  .cats {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .zones-header {
    text-align: center;
    padding: 2rem 0;
    font-size: 25px;
    /* color: darkcyan; */
    background-image: linear-gradient(to right, #d400ff, darkcyan);
    letter-spacing: 5px;
    text-transform: uppercase;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .cat-item {
    margin: 0.5rem;
    padding: 0.5rem 1rem;
    border: 0;
    border-radius: 0.2rem;
    background-color: #efefef;
    color: #666;
    &:hover {
      background-color: #ddd !important;
    }
  }
  .cat-item.active {
    background-color: black !important;
    color: #efefef !important;
  }
`;

function CategoryItem({ zone, activeZone, setZone }) {
  const { t } = useTranslation();
  const { name, _id } = zone;
  return (
    <button
      onClick={() => setZone(_id)}
      className={activeZone === _id ? "cat-item active" : "cat-item"}
    >
      {t(`zones.${name}`)}
    </button>
  );
}

function Category({ zones, activeZone, setZone }) {
  const { t } = useTranslation();
  return (
    <div css={style}>
      <div className="zones-header">{t("home.aaw")}</div>
      <div className="cats">
        {zones.map((zone, index) => (
          <CategoryItem
            key={index}
            zone={zone}
            activeZone={activeZone}
            setZone={setZone}
          />
        ))}
      </div>
    </div>
  );
}

export default Category;

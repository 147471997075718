/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState } from "react";
import { Button } from "react-bootstrap";
import RequestaLady from "./RequestaLady";
import { Carousel } from "react-bootstrap";

const style = css`
  margin: 1.5rem;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 1rem;
  border-radius: 0.5rem;
  width: 32rem !important;

  .image {
    background: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 32rem;
      height: 32rem;
      object-fit: cover;
    }
  }
  .action {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .pic2,
    .pic3 {
      height: 3rem;
      width: 3rem;
      border: 2px solid darkblue;
      border-radius: 50%;
      overflow: hidden;
      padding: 0.5rem;
      margin: 0.5rem;
    }
    .star {
      margin-top: auto;
      text-align: center;
    }
  }
  .text {
    opacity: 0.7;
    text-align: center;
    padding: 0.5rem;
    font-family: "Roboto", sans-serif;
  }

  .vote-text {
    opacity: 0.6;
  }

  @media (min-width: 601px) and (max-width: 1162px) {
    width: 30rem !important;
    img {
      width: 30rem !important;
      height: 30rem !important;
    }
  }

  @media (max-width: 600px) {
    width: 95vw !important;
    /* img{
            width: 20rem;
            height: 20rem;
        } */
  }

  @media (max-width: 400px) {
    width: 90vw !important;
  }
  .star {
    overflow: hidden;
    position: relative;
  }

  img {
    object-fit: cover;
    object-position: top;
  }
`;

const voteColors = ["#efefef", "goldenrod", "silver", "#CD7F32"];

function GualAshenda({ votes, gualAshenda, setVote, revokeVote }) {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const openPopup = () => setIsPopupOpen(true);
  const closePopup = () => setIsPopupOpen(false);

  const [isPaused, setIsPaused] = useState(true);
  const { name, pics, id, occupation, description, isActive } = gualAshenda;
  if (!isActive) return null;
  let rank = 0;
  const vote = votes.find((vote) => vote.id === id);
  if (vote) {
    rank = vote.rank;
  }

  function handleSetVote() {
    if (votes.find((vote) => vote.id === id)) {
      revokeVote(id);
    } else {
      setVote(id);
    }
  }

  function handleMouseEnter() {
    setIsPaused(false);
  }

  function handleMouseLeave() {
    setIsPaused(true);
  }

  return (
    <div css={style} className="cardii">
      <Carousel
        pause={false}
        interval={isPaused ? null : 1500}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        fade={true}
        controls={pics.length > 1}
        indicators={pics.length > 1}
      >
        {pics.map((pic, index) =>
          pic ? (
            <Carousel.Item key={index}>
              <div className="image">
                <img src={pic.url} alt={name} />
              </div>
              <Carousel.Caption>
                <p>{pic.caption}</p>
              </Carousel.Caption>
            </Carousel.Item>
          ) : null
        )}
      </Carousel>
      <div className="action">
        <div className="text">{name}</div>
        <div className="text">{occupation}</div>
        <div className="text">{description}</div>
        <div className="star">
          <button
            {...(!gualAshenda.hasPayed && { disabled: true })}
            className="btn btn-light"
            onClick={handleSetVote}
          >
            <svg
              height="48"
              width="48"
              version="1.1"
              id="Capa_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 47.94 47.94"
              xmlSpace="preserve"
              stroke={gualAshenda.hasPayed ? "darkcyan" : "#bbb"}
            >
              <path
                fill={voteColors[rank]}
                d="M26.285,2.486l5.407,10.956c0.376,0.762,1.103,1.29,1.944,1.412l12.091,1.757
                            c2.118,0.308,2.963,2.91,1.431,4.403l-8.749,8.528c-0.608,0.593-0.886,1.448-0.742,2.285l2.065,12.042
                            c0.362,2.109-1.852,3.717-3.746,2.722l-10.814-5.685c-0.752-0.395-1.651-0.395-2.403,0l-10.814,5.685
                            c-1.894,0.996-4.108-0.613-3.746-2.722l2.065-12.042c0.144-0.837-0.134-1.692-0.742-2.285l-8.749-8.528
                            c-1.532-1.494-0.687-4.096,1.431-4.403l12.091-1.757c0.841-0.122,1.568-0.65,1.944-1.412l5.407-10.956
                            C22.602,0.567,25.338,0.567,26.285,2.486z"
              />
            </svg>
          </button>
          <div className="vote-text">
            {gualAshenda.hasPayed ? "vote" : "account waiting payment"}
          </div>
          <div>
            {gualAshenda.hasPayed ? (
              <Button variant="success" onClick={openPopup}>
                Request
              </Button>
            ) : (
              <Button variant="success" disabled>
                Request
              </Button>
            )}
          </div>
        </div>{" "}
        <RequestaLady
          isOpen={isPopupOpen}
          onClose={closePopup}
          name={name}
          id={id}
        />
      </div>
    </div>
  );
}

export default GualAshenda;

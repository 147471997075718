import React from "react";
import { NavLink } from "react-router-dom";
const AdminMenu = () => {
  return (
    <>
      <div className="text-center" style={{ marginTop: "80px" }}>
        <div className="list-group dashboard-menu">
          <h4>Admin Panel</h4>
          <NavLink
            to="/dashboard/admin/users"
            className="list-group-item list-group-item-action"
          >
            All Deki Ashenda
          </NavLink>

          <NavLink
            to="/dashboard/admin/requests"
            className="list-group-item list-group-item-action"
          >
            All Requests
          </NavLink>
          <NavLink
            to="/dashboard/admin/ranking"
            className="list-group-item list-group-item-action"
          >
            Ranking
          </NavLink>
        </div>
      </div>
    </>
  );
};

export default AdminMenu;

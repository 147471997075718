/** @jsxImportSource @emotion/react */
import React, { useState, forwardRef } from "react";
import { NavLink, Link } from "react-router-dom";
import { useAuth } from "../../context/auth";
import toast from "react-hot-toast";
import useCategory from "../../hooks/useCategory";
import { useCart } from "../../context/cart";
import customAxios from "../../config/index";
import LanguageSwitcher from "../../hooks/lanSwitcher";
import { useTranslation } from "react-i18next";
import useOutsideClick from "../../hooks/outSideClick";
import Logo from "./Logo";
import { css } from "@emotion/react";

const Header = () => {
  const { t } = useTranslation();
  const [auth, setAuth] = useAuth();
  const [cart] = useCart();
  const categories = useCategory();
  const [isOpen, setIsOpen] = useState(false);
  const [isContactVisible, setIsContactVisible] = useState(false);

  const handleLogout = async () => {
    try {
      const res = await customAxios.get("/actor/logout");
      if (res && res.data.success) {
        setAuth({
          ...auth,
          user: null,
          token: "",
        });
        localStorage.removeItem("auth");
        toast.success("Logout Successfully");
      } else {
        toast.error("Failed to Logout");
      }
    } catch {
      toast.error("Something went wrong");
    }
  };

  const handleClickOutside = () => {
    setIsOpen(false);
    setIsContactVisible(false);
  };

  const toggleContactInfo = () => {
    setIsContactVisible(!isContactVisible);
  };
  const ref = useOutsideClick(handleClickOutside);

  const ContactInfo = () => (
    <div ref={ref} className="mt-3 p-3 border rounded">
      <p>
        <a href="tel:+251925439304">Phone: +251925439304</a>
      </p>
      <p>
        <a href="tel:+251979032986">Phone: +251979032986</a>
      </p>
      <p>
        <a href="tel:+251937072856">Phone: +251937072856</a>
      </p>
      <p>
        <a href="mailto:tugzaiit@gmail.com">Email: tugzaiit@gmail.com</a>
      </p>
      {/* <p>Address: </p> */}
    </div>
  );

  // Emotion CSS styles
  const navbarStyles = css`
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #f8f9fa;
    z-index: 1000;
    padding: 0 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  `;

  const navbarInnerStyles = css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 0;
    position: relative;
  `;

  const headerLeftStyles = css`
    display: flex;
    align-items: center;
    flex: 1;
  `;

  const headerCenterStyles = css`
    display: flex;
    flex: 2;
    justify-content: center;
    align-items: center;
  `;

  const headerRightStyles = css`
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: 1rem; /* Added margin to the right */
  `;

  const navItemsWrapperStyles = css`
    display: flex;
    justify-content: center;
    width: 100%;
  `;

  const navItemsStyles = css`
    display: flex;
    gap: 1rem;
    list-style: none;
    margin: 0;
    padding: 0;
    flex-wrap: wrap;
    justify-content: center;
  `;

  const navItemStyles = css`
    display: flex;
    align-items: center;
    white-space: nowrap;
  `;

  const dropdownMenuStyles = css`
    position: absolute;
    right: 0;
    top: 100%;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 0.25rem;
    min-width: 160px;
    z-index: 1050;
    display: ${isOpen ? "block" : "none"};
  `;

  const mobileStyles = css`
    @media (max-width: 768px) {
      ${navbarInnerStyles} {
        flex-direction: column;
        align-items: flex-start;
      }

      ${headerCenterStyles} {
        width: 100%;
        margin-top: 0.5rem;
      }

      ${navItemsWrapperStyles} {
        padding-top: 0.25rem;
      }

      ${navItemsStyles} {
        flex-direction: row;
        overflow-x: auto;
        width: 100%;
        padding: 0;
      }
    }
  `;

  return (
    <nav css={navbarStyles} ref={ref}>
      <div css={navbarInnerStyles}>
        <div css={headerLeftStyles}>
          <Logo />
          <Link to="/" className="navbar-brand">
            {t("terms.ashenda")}
          </Link>
        </div>

        <div css={headerCenterStyles}>
          <div css={navItemsWrapperStyles}>
            <ul css={navItemsStyles}>
              <li css={navItemStyles} className="nav-item">
                <NavLink to="/exemplary">{t("navbar.exemplary")}</NavLink>
              </li>
              <li css={navItemStyles} className="nav-item">
                <a
                  href="https://t.me/justvshare"
                  onClick={(e) => {
                    // Prevent the default action
                    e.preventDefault();

                    // Show a confirmation dialog
                    if (
                      window.confirm("Are you sure you want to open Telegram?")
                    ) {
                      // If the user confirms, redirect to the link
                      window.location.href = e.currentTarget.href;
                    }
                  }}
                >
                  {t("navbar.jobs")}
                </a>
              </li>
              {/* <li css={navItemStyles} className="nav-item">
                <a href="#" className="nav-link">
                  {t("navbar.promotions")}
                </a>
              </li> */}

              {!auth?.user ? (
                <>
                  {/* <li css={navItemStyles} className="nav-item">
                    <NavLink to="/register" className="nav-link">
                      {t("navbar.register")}
                    </NavLink>
                  </li> */}
                  <li css={navItemStyles} className="nav-item">
                    <NavLink to="/login">{t("navbar.login")}</NavLink>
                  </li>
                </>
              ) : (
                <li css={navItemStyles} className="nav-item dropdown">
                  <NavLink
                    className="nav-link dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    onClick={() => setIsOpen(!isOpen)}
                    style={{ border: "none" }}
                  >
                    {auth?.user?.name}
                  </NavLink>
                  <ul css={dropdownMenuStyles} className="dropdown-menu">
                    <li>
                      <NavLink
                        to={`/dashboard/${
                          auth?.user?.role === 1 ? "admin" : "user"
                        }`}
                        className="dropdown-item"
                      >
                        Profile
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        onClick={handleLogout}
                        to="/login"
                        className="dropdown-item"
                      >
                        Logout
                      </NavLink>
                    </li>
                  </ul>
                </li>
              )}
              <li>
                <NavLink href="#!" onClick={toggleContactInfo}>
                  {t("footer.contact")}
                </NavLink>
              </li>
            </ul>
          </div>
        </div>

        <div css={headerRightStyles}>
          <span className="nav-item">
            <LanguageSwitcher />
          </span>
        </div>
      </div>

      {/* Apply mobile styles */}
      <div css={mobileStyles} />
      {isContactVisible && <ContactInfo />}
    </nav>
  );
};

export default Header;

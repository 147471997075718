/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState } from "react";
import { Button, Carousel } from "react-bootstrap";
import customAxios from "../../../config";
import toast from "react-hot-toast";

const style = css`
  margin: 1.5rem;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 1rem;
  border-radius: 0.5rem;
  width: 32rem !important;

  .image {
    background: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 32rem;
      height: 32rem;
      object-fit: cover;
    }
  }
  .action {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .pic2,
    .pic3 {
      height: 3rem;
      width: 3rem;
      border: 2px solid darkblue;
      border-radius: 50%;
      overflow: hidden;
      padding: 0.5rem;
      margin: 0.5rem;
    }
    .star {
      margin-top: auto;
      text-align: center;
    }
  }
  .text {
    opacity: 0.7;
    text-align: center;
    padding: 0.5rem;
    font-family: "Roboto", sans-serif;
  }

  .vote-text {
    opacity: 0.6;
  }

  @media (min-width: 601px) and (max-width: 1162px) {
    width: 30rem !important;
    img {
      width: 30rem !important;
      height: 30rem !important;
    }
  }

  @media (max-width: 600px) {
    width: 95vw !important;
    /* img{
            width: 20rem;
            height: 20rem;
        } */
  }

  @media (max-width: 400px) {
    width: 90vw !important;
  }
  .star {
    overflow: hidden;
    position: relative;
  }

  img {
    object-fit: cover;
    object-position: top;
  }
`;

const voteColors = ["#efefef", "goldenrod", "silver", "#CD7F32"];

function GualAshenda({ votes, gualAshenda, setVote, revokeVote }) {
  const [isPaused, setIsPaused] = useState(true);
  const [isActive, setIsActive] = useState(gualAshenda.isActive);
  const { name, pics, id, occupation, description, phone } = gualAshenda;

  let rank = 0;
  const vote = votes.find((vote) => vote.id === id);
  if (vote) {
    rank = vote.rank;
  }

  function handleMouseEnter() {
    setIsPaused(false);
  }

  function handleMouseLeave() {
    setIsPaused(true);
  }

  async function activationToggler() {
    const { data } = await customAxios.put("/admin/activity", {
      actor: { id, active: !isActive },
    });

    if (data.success) {
      setIsActive(!isActive);
    } else {
      toast.error("An error occurred while toggling the activity.");
    }
  }

  return (
    <div css={style} className="cardii">
      <Carousel
        pause={false}
        interval={isPaused ? null : 1500}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        fade={true}
        controls={pics.length > 1}
        indicators={pics.length > 1}
      >
        {pics.map((pic, index) =>
          pic ? (
            <Carousel.Item key={index}>
              <div className="image">
                <img src={pic.url} alt={name} />
              </div>
              <Carousel.Caption>
                <p>{pic.caption}</p>
              </Carousel.Caption>
            </Carousel.Item>
          ) : null
        )}
      </Carousel>
      <div className="action">
        <div className="text">{name}</div>
        <div className="text">{occupation}</div>
        <div className="text">{description}</div>
        <div className="text">{phone}</div>{" "}
        <div className="text">
          {gualAshenda.hasPayed ? "paid" : "account waiting payment"}
        </div>
        <div className="text">
          {isActive ? (
            <Button variant="danger" onClick={activationToggler}>
              Deactivate
            </Button>
          ) : (
            <Button variant="success" onClick={activationToggler}>
              Activate
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default GualAshenda;

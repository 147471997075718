import axios from "axios";
import API_URL from "./api_url";
const customAxios = axios.create({
  baseURL: API_URL,
  withCredentials: true,
  // baseURL: "http://192.168.0.104:3000",
  //   timeout: 1000,
  //   headers: {
  //     'Content-Type': 'application/json',
  //     'Authorization': 'Bearer YOUR_ACCESS_TOKEN'
  //   }
});

export default customAxios;
